import React from "react";
import Separator from "../separator/Separator";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import TimelineOne from "./TimelineOne";

const Register = ({ total,title, features }) => {
  return (
    <>
      <Layout>
        <div className="main-content">
          {/* Start Timeline Area  */}
          <div className="rwt-timeline-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Carrito de compras"
                    title="Crea tu cuenta"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <TimelineOne total={total} title={title} features={features} />
                </div>
              </div>
            </div>
          </div>
          {/* End Timeline Area  */}

          <Separator />
        </div>
      </Layout>
    </>
  );
};
export default Register;
