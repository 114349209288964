import { useState,useRef } from "react";
import Layout from "../../common/Layout"
import SectionTitle from "../sectionTitle/SectionTitle";
import { FaHeadset,FaLocationDot,FaEnvelope } from "react-icons/fa6";
import axios from "axios";

const Contact = ()=> {
    //handlesubmint() =()
    const handleSubmit = e =>{
        e.preventDefault() 
        console.log(name, email,phone,title,body);
        axios
        .post("https://api.nextcloud.lat/api/sent/contact",{name, email, phone, title, body})
        .then(resp =>{
            console.log(resp)
        })
        e.target.reset();

    }
    const [name,setName] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()
    const [title,setTitle] = useState()
    const [body,setBody] = useState()
    const contact = useRef(0);
    const scrollToBanner = () => window.scrollTo(0, contact.current.offsetTop)
      const defaultInputStyle = {
        backgroundColor: "#fff",
        height: "50px",
        borderRadius: "10px",
        fontSize: "14px",
        marginBottom: "15px",
        border: "solid 1px rgb(159, 159, 159)",
        color: "rgba(2, 2, 2, 0.5)"
      };
      const defaultTextarea={
        height: "200px",
        border: "1px solid rgb(159, 159, 159)", 
        background: "white", 
        marginBottom:"15px", 
        color: "rgba(2, 2, 2, 0.5)"
      }
      const cardsStyle = {
        card: {
            width:"320px", 
            height:"190px", 
            border: "none", 
            borderRadius: "var(--radius-big)",
            boxShadow: "8px 8px 20px rgba(0,0,0,.1)", 
            background: "rgba(27,174,254,.1)"
        },
        container: {
            padding: "25px"
        },
        icon:{
            fontSize: '30px', color: "#1BAEFE",alignItems:"start"
        },
        title:{
            color:"rgba(54, 54, 54, 1)" , fontWeight: "bold", fontSize: "18px", marginTop: "10px", marginBottom: "10px"
        },
        subtitle:{color:"rgba(54, 54, 54, 0.75)", fontSize: "smaller"}
      }
    return(
        <>
        <Layout>
                        {/* //===========Contactanos Nexcloud===========  ref={contact} */}
            <div className="rn-service-area rn-section-gap" ref={contact} style={{paddingBottom:"35px"}} >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Formulario de contacto"
                      title="Contáctanos"
                    />
                  </div>
                </div>
                <div className="single-content">
            <div className="inner">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card" style={cardsStyle.card}>
                    <div className="container" style={cardsStyle.container}>
                    <div className="row">
                    <div className="col-12">
                    <FaHeadset style={cardsStyle.icon}/>
                    </div>
                    <div className="col-12">
                        <p style={cardsStyle.title}>
                        Teléfono
                        </p>
                    </div>
                    <div className="col-12">
                        <p style={cardsStyle.subtitle}>
                        +52 449 996 1460    
                        </p>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="col">
                <div className="card" style={cardsStyle.card}>
                     <div className="container" style={cardsStyle.container}>
                    <div className="row">
                        <div className="col-12">
                        <FaEnvelope style={cardsStyle.icon}/>
                        </div>
                        <div className="col-12">
                        <p style={cardsStyle.title}>
                        Correo electrónico 
                        </p>
                        </div>
                        <div className="col-12">
                        <p style={cardsStyle.subtitle}>
                            ayuda@kiubix.com
                        </p>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="col">
                <div className="card" style={cardsStyle.card}>
                    <div className="container" style={cardsStyle.container}>
                    <div className="row">
                        <div className="col-12">
                        <FaLocationDot style={cardsStyle.icon}/>
                        </div>
                        <div className="col-12">
                        <p style={cardsStyle.title}>
                        Dirección</p>
                        </div>
                        <div className="col-12">
                        <p style={cardsStyle.subtitle}>
                            Av. Canal Interceptor 604, Las Arboledas, 20020 Aguascalientes,Ags
                        </p>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>

            </div>
            <div>

            </div>
        </div>
        <div className="container" style={{marginTop:"30px"}}>
            <div className="row">
                <div className="col-6">
                  
                    <form action="" id="contact" method="post" onSubmit={handleSubmit}>
                    <input
                        style={defaultInputStyle}
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={e=> setName(e.target.value)} 
                      />
                    <input
                        style={defaultInputStyle}
                        type="text"
                        placeholder="Teléfono"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                    <input
                        style={defaultInputStyle}
                        type="email"
                        placeholder="Correo electrónico"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    <input
                        style={defaultInputStyle}
                        type="text"
                        placeholder="Asunto"
                        value={title}
                        onChange={e => setTitle(e.target.value)} 
                      />
                    <textarea placeholder="Mensaje" style={defaultTextarea} value={body} onChange={e=> setBody(e.target.value)}>

                    </textarea>
                    <button className="btn-secondary btn-small round" style={{width: "100% !important"}} value="login"> Enviar </button>
                    </form>
                </div>
                <div className="col-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2644.4778237826777!2d-102.30488855462895!3d21.90272895842299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1skiubix!5e0!3m2!1ses!2smx!4v1687564696564!5m2!1ses!2smx" width="500" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
              </div>
              </div>
              </div>
            </div>
          
        </Layout>
        </>
    )
}
export default Contact