import React from "react";

const Pricing = ({ handlePriceLink }) => {
  return (
    <div className="row row--0 pricing-wrapper">
      {/* Start PRicing Table Area  */}
      <div className="col-lg-3 col-md-6 col-12 pricing-container">
        <div className="rn-pricing style-4 bg-color-light-blue">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h5 className="title">Nextcloud Starter</h5>
              <div className="pricing">
                <div className="price-wrapper ">
                  <span className="currency">$</span>
                  <span className="price">4.00</span>
                </div>
                <span className="subtitle">USD por mes</span>
              </div>
            </div>
            <div className="pricing-body">
              <button
                onClick={() => handlePriceLink("price1")}
                className="btn-secondary btn-small round btn-icon"
                style={{ width: "85%" }}
              >
                Comprar ahora
              </button>
              <ul className="list-style--1">
                <li>
                  <label style={{fontSize: "14px", fontWeight: "700"}}>Almacenamiento</label><br></br>
                   200 GB
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Protección</label><br></br>
                   DDoS
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Soporte</label><br></br>
                  24/7
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Uptime</label><br></br>
                   99.99%
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Clientes</label><br></br>
                  Móviles y de escritorio
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Usuarios</label><br></br>
                  Ideal 15
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-3 col-md-6 col-12 pricing-container">
        <div className="rn-pricing style-4 bg-color-light-blue">
          {/* className="active" for active pricing styles */}
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h5 className="title">Nextcloud Standard</h5>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">$</span>
                  <span className="price">7.00</span>
                </div>
                <span className="subtitle">USD por mes</span>
              </div>
            </div>
            <div className="pricing-body">
            <button
                onClick={() => handlePriceLink("price2")}
                className="btn-secondary btn-small round btn-icon"
                style={{ width: "85%" }}
              >
                Comprar ahora
              </button>
            <ul className="list-style--1">
                <li>
                  <label style={{fontSize: "14px", fontWeight: "700"}}>Almacenamiento</label><br></br>
                   500 GB
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Protección</label><br></br>
                   DDoS
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Soporte</label><br></br>
                  24/7
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Uptime</label><br></br>
                   99.99%
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Clientes</label><br></br>
                  Móviles y de escritorio
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Usuarios</label><br></br>
                   Ideal 15
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-3 col-md-6 col-12 pricing-container">
        <div className="rn-pricing style-4 bg-color-light-blue">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h5 className="title">Nextcloud Premium</h5>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">$</span>
                  <span className="price">15.00</span>
                </div>
                <span className="subtitle">USD por mes</span>
              </div>
            </div>
            <div className="pricing-body">
            <button
                onClick={() => handlePriceLink("price3")}
                className="btn-secondary btn-small round btn-icon"
                style={{ width: "85%" }}
              >
                Comprar ahora
              </button>
            <ul className="list-style--1">
                <li>
                  <label style={{fontSize: "14px", fontWeight: "700"}}>Almacenamiento</label><br></br>
                   1 TB
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Protección</label><br></br>
                   DDoS
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Soporte</label><br></br>
                  24/7
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Uptime</label><br></br>
                   99.99%
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Clientes</label><br></br>
                  Móviles y de escritorio
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Usuarios</label><br></br>
                   Ideal 15
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-3 col-md-6 col-12 pricing-container">
        <div className="rn-pricing style-4">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h5 className="title">Nextcloud Advanced</h5>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">$</span>
                  <span className="price">35.00</span>
                </div>
                <span className="subtitle">USD por mes</span>
              </div>
            </div>
            <div className="pricing-body">
            <button
                onClick={() => handlePriceLink("price4")}
                className="btn-secondary btn-small round btn-icon"
                style={{ width: "85%" }}
              >
                Comprar ahora
              </button>
            <ul className="list-style--1">
                <li>
                  <label style={{fontSize: "14px", fontWeight: "700"}}>Almacenamiento</label><br></br>
                   3 TB
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Protección</label><br></br>
                   DDoS
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Soporte</label><br></br>
                  24/7
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Uptime</label><br></br>
                   99.99%
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Clientes</label><br></br>
                  Móviles y de escritorio
                </li>
                <li>
                <label style={{fontSize: "14px", fontWeight: "700"}}>Usuarios</label><br></br>
                   Ideal 15
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}
    </div>
  );
};
export default Pricing;
