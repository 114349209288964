import Layout from "../../common/Layout"
import SectionTitle from "../sectionTitle/SectionTitle";  //=====functions to scroll to sections====//
  
  const Privacity = () =>{
  const defaultLabel = {
    color: "black",
    fontWeight: "700",
    fontSize: "20px"
  };
  const defaulParra = {
    color:"black",
    fontSize: "14px",
    fontWeight: "400"
  }; 
    return(
      <>
      <Layout>
        <main className="page-wrapper">
              {/* //===========Contactanos Nexcloud===========  */}
              <div className="rn-service-area rn-section-gap">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="Política de uso de datos y privacidad"
                        title="Política de uso de datos y privacidad"
                      />
                    </div>
                  </div>
                  <div className="single-content">
              <div className="inner">
              <div className="container">
                  <div className="row">
                      <p style={defaulParra}>
                      En Nexcloud, nos preocupamos profundamente por la privacidad y seguridad de nuestros usuarios y sus datos. Queremos abordar y aclarar cualquier inquietud que pueda haber surgido en relación con nuestra política de privacidad. A continuación, destacamos algunos puntos importantes<br></br></p>
                      <label style={defaultLabel}>Protección de datos</label>
                      <p style={defaulParra}>
                      En Nexcloud, nunca vendemos los datos de nuestros usuarios y nunca lo haremos en el futuro. Valoramos la confianza que depositan en nosotros y nos comprometemos a mantener sus datos seguros y confidenciales.<br></br>
                      </p>
                      <label style={defaultLabel}>Supervisión de reuniones y contenido</label>
                      <p style={defaulParra}>
                      Queremos dejar claro que Nexcloud no supervisa las reuniones ni el contenido de los usuarios. Respetamos su privacidad y consideramos que sus datos personales y comunicaciones son de su exclusiva responsabilidad y propiedad. No realizamos seguimiento de sus actividades o interacciones dentro de la plataforma.<br></br>
                      </p>
                      <label style={defaultLabel}>Privacidad de archivos locales</label>
                      <p style={defaulParra}>
                      En Nexcloud, somos un servicio de almacenamiento y colaboración en la nube. Queremos asegurarte que no supervisamos los archivos almacenados en tus dispositivos locales. Tu privacidad y la confidencialidad de tus datos son fundamentales para nosotros.<br></br>
                      </p>
                      <label style={defaultLabel}>Recopilación y retención de información</label>
                      <p style={defaulParra}>
                      Necesitamos cierta información personal para crear una cuenta y brindar nuestros servicios de manera efectiva. Sin embargo, sólo conservamos esta información durante el tiempo necesario para los fines para los cuales se recopiló. En general, mantenemos tus datos personales mientras tu cuenta de Nexcloud esté activa. Sin embargo, algunos datos pueden eliminarse directamente de nuestros servicios.<br></br>
                      </p>
                      <label style={defaultLabel}>Información retenida hasta la eliminación de tu cuenta</label>
                      <p style={defaulParra}>
                      Durante el tiempo que tengas una cuenta activa en Nexcloud, conservamos algunos datos como tu nombre de usuario, dirección de correo electrónico y número de teléfono. Parte de esta información puede retenerse por períodos más prolongados para fines específicos, como se detalla a continuación. Pero recuerda que siempre tienes la opción de eliminar tu cuenta en cualquier momento si así lo deseas.<br></br>
                      En Nexcloud, nos comprometemos a proteger tu privacidad y seguridad en todo momento. Valoramos tu confianza y estaremos encantados de responder cualquier pregunta o inquietud que puedas tener sobre nuestra política de uso de datos y privacidad.<br></br>
                      </p>
                      <label style={defaultLabel}>¿Qué sucede cuando eliminas tu cuenta?</label>
                      <p style={defaulParra}>
                      Cuando eliminas tu cuenta en Nextcloud, varios aspectos se ven afectados. Aquí están algunas de las consecuencias más comunes:<br></br>
                      </p>
                      <ol>
                          <ul style={defaulParra}>
                          1. Pérdida de acceso a tus archivos y datos: Al eliminar tu cuenta, perderás acceso a todos los archivos y datos almacenados en tu espacio de Nextcloud. Esto incluye documentos, fotos, videos y cualquier otro tipo de archivo que hayas subido a tu cuenta.<br></br>
                          </ul>
                          <ul style={defaulParra}>
                          2. Eliminación de la sincronización y respaldo automático: Si estabas utilizando Nextcloud para sincronizar y respaldar automáticamente tus archivos desde dispositivos conectados, esta funcionalidad dejará de estar disponible una vez que elimines tu cuenta.<br></br>
                          </ul>
                          <ul style={defaulParra}>
                          3. Finalización del uso compartido de archivos y colaboración: Si estabas compartiendo archivos con otros usuarios de Nextcloud o colaborando en proyectos conjuntos, la eliminación de tu cuenta interrumpirá esas colaboraciones y el acceso de otros usuarios a los archivos compartidos.<br></br>
                          </ul>
                          <ul style={defaulParra}>
                          4. Cese de la capacidad de acceder a aplicaciones y complementos asociados: Si utilizamos aplicaciones y complementos específicos dentro de Nextcloud, es posible que pierdas acceso a ellos una vez que elimines tu cuenta. Esto incluye aplicaciones de productividad, administradores de tareas, calendarios, entre otros.<br></br>
                          </ul>
                      </ol>
                      <p style={defaulParra}>
                      Es importante tener en cuenta que los detalles específicos de la eliminación de una cuenta pueden variar según la configuración y la implementación de Nextcloud que estés utilizando. Siempre es recomendable hacer una copia de seguridad de tus datos importantes antes de eliminar tu cuenta.<br></br>
                      </p>
                  </div>
              </div>
                </div>
                </div>
                </div>
              </div>
        </main>
      </Layout>
    </>
    )
}
export default Privacity