import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

const Loading = () => {
    return(
        <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
}
export default Loading 