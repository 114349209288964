import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import Home from "./pages/Home";
// Elements import Here
import Error from "./pages/Error";
// Import Css Here
import "./assets/scss/style.scss";
import Recovery from "./pages/recovery";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/error" exact component={Error} />
          <Route path="/recovery" exact component={Recovery} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
