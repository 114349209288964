import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

//=====Icons====//
import gobIcon from "../../assets/images/Icon-Gobiernos.png";
import empresasIcon from "../../assets/images/Icon-Empresas.png";
import servicionsIcon from "../../assets/images/Icon-Servicios.png";
import eduIcon from "../../assets/images/Icon-Educacion.png";

const ServiceList = [
  {
    icon: <img src={gobIcon} alt="gobiernos" />,
    title: "Gobiernos",
    description:
      "Los gobiernos se están alejando de los proveedores de nube extranjeros en medio de la creciente preocupación por la soberanía digital.",
  },
  {
    icon: <img src={empresasIcon} alt="empresas" />,
    title: "Empresas",
    description:
      "Ayuda a las empresas a mejorar la eficiencia, aborda los riesgos de cumplimiento, costos y fugas de datos en implementaciones estratégicas.",
  },
  {
    icon: <img src={servicionsIcon} alt="servicios" />,
    title: "Servicios",
    description:
      "Aproveche su infraestructura y haga que sus clientes sean suyos con una plataforma totalmente personalizada que ofrece una gran experiencia de usuario.",
  },
  {
    icon: <img src={eduIcon} alt="educacion" />,
    title: "Educación",
    description:
      "Aproveche su infraestructura y mantenga a sus clientes como suyos con una plataforma de marca completa que ofrece una gran experiencia de usuario.",
  },
];

const CasosDeUso = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 casos-de-uso-wrapper">
      {ServiceList.map((val, i) => (
        <div
          className="col-lg-3 col-md-6 col-sm-6 col-12 casos-de-uso-child"
          key={i}
        >
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4
                  className="title w-600 titleCasoUsos"
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default CasosDeUso;
