import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import prodIcon from "../../assets/images/Icon-Productividad.png";
import controlIcon from "../../assets/images/Icon-Control.png";

const ServiceList = [
  {
    icon: <img src={prodIcon} alt="productividad" />,
    title: "Productividad",
    description:
      "Nextcloud permite la productividad en cualquier plataforma y acceso transparente a los datos en cualquier almacenamiento, fomentando la colaboración y comunicación más allá de los límites de la organización.",
  },
  {
    icon: <img src={controlIcon} alt="control" />,
    title: "Control",
    description:
      "Protege, controla y supervisa los datos y la comunicación de tu empresa con cumplimiento empresarial y legal. Mantén la propiedad y privacidad de tus datos en servidores seguros y evita filtraciones.",
  },
  {
    icon: <img src="./images/service/Icon-Comunidad.png" alt="communidad" />,
    title: "Comunidad",
    description:
      "Disfruta de un modelo de desarrollo comunitario transparente y en constante mejora, sin bloqueos ni pagos adicionales. Obtén ventajas del soporte empresarial cuando lo necesites.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4
                  className="title w-600"
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></h4>
                <p
                  className="description b3 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
