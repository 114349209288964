import Layout from "../../common/Layout"
import SectionTitle from "../sectionTitle/SectionTitle";
//=====functions to scroll to sections====//
  
  const Termins = () =>{
  const defaultLabel = {
    color: "black",
    fontWeight: "700",
    fontSize: "20px"
  };
  const defaulParra = {
    color:"black",
    fontSize: "14px",
    fontWeight: "400"
  }; 
    return(
      <>
      <Layout>
      <main className="page-wrapper">

            {/* //===========Contactanos Nexcloud===========  */}
            <div className="rn-service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Términos y condiciones"
                      title="Términos de uso"
                    />
                  </div>
                </div>
                <div className="single-content">
            <div className="inner">
            <div className="container">
                <div className="row">
                    <label style={defaultLabel}>Aceptación de los Términos</label>
                    <p style={defaulParra}>
                    Estas son las condiciones de uso ("CDU") que se aplican al utilizar el sitio web de Nextcloud (http://nextcloud.lat/#) o cualquier servicio asociado. Nextcloud se reserva el derecho de actualizar los TDU en cualquier momento sin previo aviso.<br></br>
                    </p>
                    <label style={defaultLabel}>Descripción del Servicio</label>
                    <p style={defaulParra}>
                    Nextcloud se destaca como un servicio popular debido a su capacidad para aumentar la productividad, proporcionar un control sólido sobre los datos y la comunicación, y beneficiarse de una comunidad activa de desarrollo. Estas características hacen de Nextcloud una opción atractiva para empresas y organizaciones que buscan una plataforma confiable y segura de colaboración y gestión de contenido.<br></br>
                    </p>
                    <label style={defaultLabel}>Colaboración y almacenamiento en la nube de código abierto</label>
                    <p style={defaulParra}>
                    Nextcloud es un software de colaboración y almacenamiento en la nube de código abierto. Está licenciado bajo los términos de la Licencia Pública General Affero (AGPL, por sus siglas en inglés), que es una licencia de software libre y de código abierto. La licencia AGPL garantiza la libertad de usar, modificar y distribuir el software, así como acceder y utilizar sus funcionalidades a través de la web.<br></br>
                    </p>
                    <label style={defaultLabel}>Información que creas o nos proporcionas</label>
                    <p style={defaulParra}>
                    Al crear una cuenta de Nextcloud, nos proporcionas información personal que incluye tu nombre y una contraseña. También puedes añadir un número de teléfono o datos de pago a tu cuenta. 
                    </p>
                    <label style={defaultLabel}>Protección de tu información</label>
                    <p style={defaulParra}>
                    Estas son las condiciones de uso ("CDU") que se aplican al utilizar el sitio web de Nextcloud (http://nextcloud.lat/#) o cualquier servicio asociado. Nextcloud se reserva el derecho de actualizar los TDU en cualquier momento sin previo aviso.<br></br>
                    </p>
                    <label style={defaultLabel}>Gestión, revisión y actualización de tu información</label>
                    <p style={defaulParra}>
                    Si has iniciado sesión, puedes revisar y actualizar la información en cualquier momento accediendo a los servicios que utilizas. Por ejemplo, Fotos y Drive están diseñados para ayudarte a gestionar tipos específicos de contenido que has guardado en Nextcloud. También hemos creado un espacio donde puedes revisar y controlar la información que has guardado en tu cuenta de Nextcloud.<br></br>
                    </p>
                    <label style={defaultLabel}>Medidas que tomamos en caso de problemas</label>
                    <p style={defaulParra}>
                    Antes de tomar las medidas que se indican a continuación, te avisaremos con suficiente antelación cuando sea razonablemente posible, te explicaremos los motivos de nuestras acciones y te daremos la oportunidad de solucionar el problema, a menos que tengamos razones suficientes para creer que hacerlo podría:<br></br>
                    </p>
                    <ul>
                        <li style={defaulParra}>
                            Provocar daños o incurrir en responsabilidades para un usuario, un tercero o Nextcloud.
                        </li>
                        <li style={defaulParra}>
                            Infringir la ley o una orden de una autoridad judicial.
                        </li>
                        <li style={defaulParra}>
                            Poner en peligro una investigación.
                        </li>
                        <li style={defaulParra}>
                            Poner en peligro el funcionamiento, integridad o seguridad de nuestros servicios.
                        </li>
                    </ul>
                    <label style={defaultLabel}>Integramos la seguridad en nuestros servicios para proteger la información de nuestros usuarios</label>
                    <p style={defaulParra}>
                    Todos los productos de Nextcloud incluyen funciones de seguridad sólidas que protegen constantemente tu información. Mantener nuestros servicios nos permite detectar y bloquear automáticamente amenazas de seguridad para que nunca te veas afectado. Si detectamos algún riesgo que consider<br></br>
                    </p>
                </div>
            </div>
              </div>
              </div>
              </div>
            </div>
      </main>
      </Layout>
    </>
    )
}
export default Termins