import React, { useRef, useState } from "react";
import FooterFour from "../../common/footer/FooterFour";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import EnterpriseLogo from "../../assets/images/Banner-Enterpise.svg";
import Layout from "../../common/Layout";
  //=====functions to scroll to sections====//
  
  const Login = () =>{
    const [email, setEmail] = useState(null);
    const [showPwd, setShowPwd] = useState(false);
    const [password, setPassword] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const login = useRef(0)
      const handleEmail = (event) => {
        setEmail(event.target.value);
      };
      const handlePassword = (event) => {
        setPassword(event.target.value);
      };
      const handleShowPwd = () => {
        setShowPwd(!showPwd);
      };
      
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };
      //===== Style for input with search icon =====//
  const defaultInputStyle = {
    backgroundColor: "#fff",
    height: "40px",
    borderRadius: "10px",
    fontSize: "14px",
    marginBottom: "15px"
  };
  const inputStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "right 10px center",
    backgroundRepeat: "no-repeat", */
    paddingRight: "40px", // adjust this value to match the width of your icon
    boxSizing: "border-box",
    backgroundColor: "#fff",
    width: "80%",
    height: "40px",
    borderRadius: "10px",
    fontSize: "14px",
    paddingLeft: "10px",
    borderRadius: "10px 0px 0px 10px"
  };
  const inputButtonStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",  */
    width: "15%",
    height: "40px",
    marginBottom: "3px",
    borderRadius: "0px 10px 10px 0px"
    
  }
    return(
      <>
      <Layout>
      <main className="page-wrapper" ref={login}>
            {/* //===========Por que Nexcloud===========  */}
            <div className="rn-service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Iniciar sesión"
                      title="Bienvenido de vuelta"
                    />
                  </div>
                </div>
                <div className="single-content" style={{paddingBottom: "120px"}}>
            <div className="inner">

            <div className="card" style={{ border: "none", borderRadius: "var(--radius-big)",boxShadow: "8px 8px 20px rgba(0,0,0,.1)", background: "rgba(27,174,254,.1)"}}>
              <div className="card-body" style={{margin: "25px", padding: "25px"}}>
                <div className="container">
                  <div className="row">
                    <div className="col" style={{paddingTop:"65px"}}>
                      <h1 style={{color: "black"}}>Iniciar sesión</h1>
                      <p>
                      Disfruta de un modelo de desarrollo comunitario transparente y en constante mejora, sin bloqueos ni pagos adicionales. 
                      </p>
                    <form>
                      <input
                        style={defaultInputStyle}
                        type="email"
                        placeholder="Correo electrónico"
                        value={email}
                        onChange={handleEmail}
                      />
                      <div className="pwd-container">
                        <input
                         style={defaultInputStyle}
                         className="pwd-input"
                         type={showPwd ? "text" : "password"}
                         placeholder="Contraseña"
                         value={password}
                         onChange={handlePassword}
                        />
                      </div>
                      <div>
                            <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                            <label className="form-check-label" for="flexCheckDefault" style={{color:"black"}}> Mantener sesión iniciada </label>
                          </div>
                      </div>

                      <button className="btn-secondary round" style={{width: "100% "}}> Iniciar sesión </button>
                      <div>
                            <label className="form-check-label" for="flexCheckDefault" style={{color:"black"}}> ¿Olvidaste tu contraseña?  <a href="/recovery" style={{color: "blue"}}>Restablecer contraseña</a> </label>
                      </div>
                    </form>
                    </div>

                    <div className="col">
                    <div className="thumbnail">
                    <img
                      className="w-100"
                      src={EnterpriseLogo}
                      alt="Corporate React Template"
                    />
                  </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
              </div>
              </div>
              </div>
            </div>

      </main>
      </Layout>
    </>
    )
}
export default Login