import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiCheck } from "react-icons/fi";

const TabModule = () => {
  const [selectedButton, setSelectedButton] = useState("files");
  console.log(selectedButton);
  const handleTabChange = (buttonId) => {
    setSelectedButton(buttonId);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab
                        onClick={() => {
                          handleTabChange("files");
                        }}
                      >
                        <div className="rn-tab-button">
                          <button
                            className={
                              selectedButton === "files"
                                ? "selected-button"
                                : ""
                            }
                          >
                            Nextcloud Files
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        onClick={() => {
                          handleTabChange("talk");
                        }}
                      >
                        <div className="rn-tab-button">
                          <button
                            className={
                              selectedButton === "talk" ? "selected-button" : ""
                            }
                          >
                            Nextcloud Talk
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        onClick={() => {
                          handleTabChange("groupware");
                        }}
                      >
                        <div className="rn-tab-button">
                          <button
                            className={
                              selectedButton === "groupware"
                                ? "selected-button"
                                : ""
                            }
                          >
                            Nextcloud Groupware
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        onClick={() => {
                          handleTabChange("office");
                        }}
                      >
                        <div className="rn-tab-button">
                          <button
                            className={
                              selectedButton === "office"
                                ? "selected-button"
                                : ""
                            }
                          >
                            Nextcloud Office
                          </button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    {/* //======Nextcloud Files Section========// */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="section-one">
                          <div className="section-title">
                            <img
                              src="./images/tab/Icon-Nextcloud-Files.png"
                              alt=""
                            />
                            <h4 className="title">Nextcloud Files</h4>
                          </div>
                          <p>
                            Nextcloud Files ofrece una plataforma de acceso y
                            sincronización universal de archivos local con
                            potentes funciones de colaboración e interfaces de
                            escritorio, móviles y web.
                          </p>
                          <ul className="list-icon">
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Fácil acceso en cualquier lugar
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Potente control de acceso
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Integración de la infraestructura
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Diseñado para los humanos
                            </li>
                          </ul>
                        </div>

                        <div className="section-two">
                          <div className="image-container">
                            <img
                              className="tab-image"
                              src="./images/tab/IMG-Nextcloud-Files.svg"
                              alt="Nextcloud Logo"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* //======Nextcloud Talk Section========// */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="section-one">
                          <div className="section-title">
                            <img
                              src="./images/tab/Icon-Nextcloud-Talk.svg"
                              alt=""
                            />
                            <h4 className="title">Nextcloud Talk</h4>
                          </div>
                          <p>
                            Nextcloud Talk ofrece conferencias de audio/vídeo
                            privadas y chat de texto en las instalaciones a
                            través de interfaces de navegador y móvil con
                            pantalla compartida integrada e integración SIP.
                          </p>
                          <ul className="list-icon">
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Chat de grupo
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Conferencias web
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Compartir pantalla
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Evita las fugas de datos
                            </li>
                          </ul>
                        </div>

                        <div className="section-two">
                          <div className="image-container">
                            <img
                              className="tab-image"
                              src="./images/tab/IMG-Nextcloud-Talk.svg"
                              alt="Nextcloud Logo"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* //======Nextcloud Groupware Section========// */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="section-one">
                          <div className="section-title">
                            <img
                              src="./images/tab/Icon-Nextcloud-Groupware.svg"
                              alt=""
                            />
                            <h4 className="title">Nextcloud Groupware</h4>
                          </div>
                          <p>
                            Nextcloud Groupware integra el calendario, los
                            contactos, el correo y otras funciones de
                            productividad para ayudar a los equipos a realizar
                            su trabajo de forma más rápida, sencilla y en sus
                            condiciones.
                          </p>
                          <ul className="list-icon">
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Planificación de equipos
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Acceso fácil, en cualquier lugar
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              El correo electrónico es más fácil
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Utiliza tus aplicaciones actuales
                            </li>
                          </ul>
                        </div>

                        <div className="section-two">
                          <div className="image-container">
                            <img
                              className="tab-image"
                              src="./images/tab/IMG-Nextcloud-Groupware.svg"
                              alt="Nextcloud Logo"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* //======Nextcloud Office Section========// */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="section-one">
                          <div className="section-title">
                            <img
                              src="./images/tab/Icon-Nextcloud-Office.svg"
                              alt=""
                            />
                            <h4 className="title">Nextcloud Office</h4>
                          </div>
                          <p>
                            Nextcloud Office es una potente suite en línea
                            basada en LibreOffice con edición colaborativa, que
                            admite los principales formatos de archivo de
                            documentos, hojas de cálculo y presentaciones.
                          </p>
                          <ul className="list-icon">
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Oficina online autogestionada
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Ver y responder a los comentarios
                            </li>
                            <li>
                              <span className="icon">
                                <FiCheck />
                              </span>{" "}
                              Chatee o reciba una llamada mientras edita
                            </li>
                          </ul>
                        </div>

                        <div className="section-two">
                          <div className="image-container">
                            <img
                              className="tab-image"
                              src="./images/tab/IMG-Nextcloud-Office.svg"
                              alt="Nextcloud Logo"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabModule;
