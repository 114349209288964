import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import useStickyHeader from "./useStickyHeader";
import { FaUserLarge } from "react-icons/fa6";

const Header = ({
  btnStyle,
  HeaderSTyle,
  scrollToPorque,
  scrollToHub,
  scrollToUsos,
  scrollToPrice,
  handleRegister,
  handleContant,
  handlePolitics,
  handleTermins,
  handleLogin,
  scrollToBanner
}) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check] = useState(true);
  const sticky = useStickyHeader(100);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const loginButton = {
    background: "white",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    cursor: "pointer"
  }
  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <div>
                  <a href="/">
                    <Logo image="/images/logo/Logo-Header.png" />
                  </a>
                </div>
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav
                    scrollToPorque={scrollToPorque}
                    scrollToHub={scrollToHub}
                    scrollToUsos={scrollToUsos}
                    scrollToBanner ={scrollToBanner}
                    scrollToPrice={scrollToPrice}
                    handleContant={handleContant}
                    handlePolitics = {handlePolitics}
                    handleRegister={handleRegister}
                    handleLogin={handleLogin}
                    handleTermins = {handleTermins}
                  />
                </nav>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-8">
              <div className="header-right">
                <div className="header-btn">
                  <button
                    className={`btn-default ${btnStyle}`}
                    onClick={scrollToPrice}
                  >
                    Ir al demo
                  </button>
                </div>
                <div className="header-btn">
                  <div
                    onClick={handleLogin}
                    style={loginButton  }
                  >
                    <FaUserLarge style={{color:"#1BAEFE", fontSize: "20px",margin: "10px"}}/>
                  </div>
                </div>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default Header;
