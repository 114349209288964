import React, { useState, useRef, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import EnterpriseLogo from "../../assets/images/Banner-Enterpise.svg";
import { FiCheck } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
import SuccessMessage from "./SuccessMessage";
import Loading from "../button/loader";
import axios from "axios";
import {Badge} from 'react-bootstrap';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {Modal, Button} from 'react-bootstrap';

const TimelineOne = ({ total, title , features }) => {
  //===state for terms & conditions==//
  const [isChecked, setIsChecked] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  //==states for register form==//
  const [fullName, setFullName] = useState(null);
  const [apellido, setApellido] = useState(null)
  const [email, setEmail] = useState(null);
  const [showPwd, setShowPwd] = useState(false);
  const [domain, setDomain] = useState(null)
  const [loading, setLoading] = useState(false);
  const [listDomain, setListDomain] = useState([])
  const [getDomain, setGetDomain] = useState(null)
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [bodyModal, setBodyModal] = useState(null);
  const [user, setUser] = useState(null);

  
  //=====functions to handle input changes=====//
  const handleName = (event) => {
    setFullName(event.target.value);
  };
  const handleApellido = (event) => {
    setApellido(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleDomain = (event) =>{
    setDomain(event.target.value)
  }
  const handleLoading = ()=>{
    setLoading(true)
  }

  const handleGetDomain = (event)=>{
    setGetDomain(event)
  }

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  //=====sections to scroll to home====//
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);

  //=====functions to scroll to sections====//
  const scrollToStep1 = () => step1.current.scrollIntoView();
  const scrollToStep2 = () => step2.current.scrollIntoView();
  const scrollToStep3 = () => step3.current.scrollIntoView();

  const TimelineData = [
    {
      id: "1",
      date: "Paso 1",
      ref: step1,
      title: "¿Cómo se llamará tu proyecto?",
      image: EnterpriseLogo,
      nextButton: "step 2",
      workingStep: [
        {
          stepTitle: "Comunidad",
          stepDescription:
            "Disfruta de un modelo de desarrollo comunitario transparente y en constante mejora, sin bloqueos ni pagos adicionales. ",
        },
        {
          stepTitle: "Comunidad",
          stepDescription:
            "Disfruta de un modelo de desarrollo comunitario transparente y en constante mejora, sin bloqueos ni pagos adicionales. ",
        },
      ],
    },
    {
      id: "2",
      ref: step2,
      date: "Paso 2",
      title: "Registrate",
      previousButton: "step 1",
      nextButton: "step 3",
      description:
        "Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.",
      image: EnterpriseLogo,
      workingStep: [
        {
          stepTitle: "Follow this process",
          stepDescription:
            "Lorem ipsum dolor sit amet at consectetur adipisicing",
        },
        {
          stepTitle: "Working experience",
          stepDescription:
            "Lorem ipsum dolor sit amet at consectetur adipisicing",
        },
      ],
    },
    {
      id: "3",
      ref: step3,
      date: "Paso 3",
      title: "Detalles de paquete",
      previousButton: "step 2",
      nextButton: "go to payment",
      description:
        "Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.",
      image: EnterpriseLogo,
      workingStep: [
        {
          stepTitle: "Follow this process",
          stepDescription:
            "Lorem ipsum dolor sit amet at consectetur adipisicing",
        },
        {
          stepTitle: "Working experience",
          stepDescription:
            "Lorem ipsum dolor sit amet at consectetur adipisicing",
        },
      ],
    },
  ];

  //=====Function to check if user read terms and conditions====//

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  //====== Function to move through steps ======//
  const jumpTo = (section) => {
    switch (section) {
      case "step 1":
        scrollToStep1();
        break;
      case "step 2":
        scrollToStep2();
        break;
      case "step 3":
        scrollToStep3();
        break;
      default:
        console.log("Invalid section");
        break;
      }
    };
    
    const findDomain = async() => {
      try {
        handleLoading()
        const domainName = domain
          axios
          .post('https://api.nextcloud.lat/api/get/domain',{domainName: domainName})
          .then(resp => {
            setLoading(false)
            setListDomain(resp.data?.data)
        })
      } catch (error) { 
        console.log(error)
      }
    }
    const validateUser = () =>{
      try {
        const data = {
          fullName: fullName,
          apellido: apellido,
          email: email,
          getDomain: getDomain
        }
          axios
          .post('https://api.nextcloud.lat/api/sent/saveInfoUser',data)
          .then(resp => {
            if ( resp.data.data == "A user already exists with that email address") {
              setTitleModal(resp.data?.message)
              setBodyModal("Ya existe un usuario registrado con este correo")
              handleShow()
            }else{
              setShow(true)
              setUser(resp.data.data)
            }
        })

      } catch (error) {
        console.log(error)
      }
    }


    const createOrder = (data, actions) => {
          return actions.order.create({
            purchase_units: [
                {
                    description: title,
                    amount: {
                        currency_code: "USD",
                        value: total,
                    },
                },
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
        const { payer } = details;
        setSuccess(true);
    });
};
const onError = (data, actions) => {
  setErrorMessage("An Error occured with your payment ");
};

useEffect(() => {
  if (success) {

       console.log(orderID, fullName, apellido, email, getDomain, user)

        const data = {
          orderID: orderID,
          fullName: fullName,
          apellido: apellido,
          email: email,
          getDomain: getDomain,
          user_id: user
        }
       axios
          .post('https://api.nextcloud.lat/api/sent/payloader',data)
          .then(resp => {
            console.log(resp)
        }) 
  }
},[success]);
  //===== Style for input with search icon =====//
  const defaultInputStyle = {
    backgroundColor: "#fff",
    height: "40px",
    borderRadius: "10px",
    fontSize: "14px",
    margin: ".5rem 0",
  };
  const inputStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "right 10px center",
    backgroundRepeat: "no-repeat", */
    paddingRight: "40px", // adjust this value to match the width of your icon
    boxSizing: "border-box",
    backgroundColor: "#fff",
    width: "75%",
    height: "40px",
    fontSize: "14px",
    paddingLeft: "10px",
  };
  const inputButtonStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",  */
    width: "10%",
    height: "40px",
    marginBottom: "3px",
    borderRadius: "0px 10px 10px 0px"
    
  }
  const inputStyleContra = {
    /*     backgroundImage: `url(${searchIcon})`,
        backgroundPosition: "right 10px center",
        backgroundRepeat: "no-repeat", */
        paddingRight: "40px", // adjust this value to match the width of your icon
        boxSizing: "border-box",
        backgroundColor: "#fff",
        width: "75%",
        height: "40px",
        borderRadius: "10px",
        fontSize: "14px",
        paddingLeft: "10px",
      };
  const inputButtonStyleContra = {

        width: "15%",
        height: "40px",
        marginBottom: "3px",
        borderRadius: "0px 10px 10px 0px",
        backgroundColor: 'white',
        border: "solid 0",
        color: "#0d6efd"        
        
      }
  return (
    <PayPalScriptProvider options={{ "client-id": "Aehl33k-3vi23HQjgsTiVhwqosOTUhL3REHLs_U3J_BERE-YkSdPvUag_zNq39G2jc6SyzHtjo8sEepP" }}>
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}
      <div className="custom-loader"></div>

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50"style={{marginBottom : "5rem"}} key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          {/* <div>
            <SuccessMessage />
          </div> */}
          <div className="single-content" ref={data.ref}>
            <div className="inner">
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ color: "blue", width: "50%" }}>
                  <div className="content">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <span className="date-of-timeline">{data.date}</span>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title">{data.title}</h2>
                    </ScrollAnimation>
                    {data.id == 1 ? (
                      <>
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                          <div className="input-group">
                          <div className="input-group-text" id="btnGroupAddon" style={{height:"40px", borderRadius: "10px 0px 0px 10px"}}>server.</div>
                            <input
                              style={inputStyle1}
                              type="text"
                              value={domain}
                              onChange={handleDomain}
                              placeholder="tu dominio / empresa "
                            />
                              <button 
                                className="btn btn-outline-primary" 
                                type="button" 
                                id="button-addon2"
                                onChange={handleLoading}
                                onClick={findDomain}
                                style={inputButtonStyle1}><BsSearch style={{fontSize: '20px'}}
                                />
                              </button>
                          </div>
                        </ScrollAnimation>
                        { loading ? (
                          <Loading />
                        ) :(
                        <div className="row row--30">
                         <div className="container">
                          <div className="row">
                          <h5 className="working-title">
                          Dominios disponibles:
                          </h5>
                          </div>
                            <div className="row">

                            {listDomain.map((item) => (
                              <div className="col-md-auto" onClick={(event)=>handleGetDomain(item)}>
                              <Badge variant="info" style={{background: "green",cursor:"pointer"}}>
                                {item}
                              </Badge>
                              </div>
                            ))}
                            </div>
                            <div className="row">
                              <h3 className="working-title">
                              {getDomain}
                              </h3>
                            </div>
                         </div>
                        </div>
                        )}
                      </>
                    ) : data.id == 2 ? (
                      <>
                        <form>
                        <input
                            style={defaultInputStyle}
                            type="text"
                            placeholder="Nombre"
                            value={fullName}
                            onChange={handleName}
                          />
                          <input
                            style={defaultInputStyle}
                            type="text"
                            placeholder="Apellido"
                            value={apellido}
                            onChange={handleApellido}
                          />
                          <input
                            style={defaultInputStyle}
                            type="email"
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={handleEmail}
                          />
{/*                           <div className="step1-input">
                            <input
                              className="pwd-input"
                              style={inputStyle1}
                              type={showPwd ? "text" : "password"}
                              placeholder="Contraseña"
                              value={password}
                              onChange={handlePassword}
                            />                              
                            <button 
                            className="btn btn-outline-primary" 
                            type="button" 
                            id="button-addon2"
                            onClick={()=>setShowPwd(!showPwd)}
                            style={inputButtonStyleContra}>
                              {
                                showPwd ?  <BsEyeSlashFill style={{fontSize: '20px'}}/> : <BsEyeFill style={{fontSize: '20px'}}/>
                              }
                          </button>
                          </div>
                          <div className="pwd-container">
                            <input
                              className="pwd-input"
                              type={showConfirmPwd ? "text" : "password"}
                              placeholder="Confirmar contraseña"
                              value={confirmPwd}
                              onChange={handleConfirmPwd}
                            />
                            <img
                              src={eyeIcon}
                              alt="eye"
                              onClick={handleShowConfirm}
                            />
                          </div> */}
                          <div className="row">
                          <div className="terms-checkbox">
                              <div>
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{width: "20px",height: "20px"}}/>
                                <label className="form-check-label" for="flexCheckDefault" style={{fontSize: "12px",color:"black"}}>  He leído y estoy de acuerdo con los{" "}</label> <a href="#" style={{fontSize: "12px",color: "blue"}}>términos y condiciones</a>
                              </div>
                            </div>
                          </div>

                          </div>
                        </form>
                      </>
                    ) : data.id == 3 ? (
                      <div>
                        <div>
                          <h3 style={{"color": "#1baefe", "fontStyle": "italic"}}>{title}</h3>
                        </div>
                        <div className="details-content">
                          <div className="details-list">
                            <div className="row">
                              {features.map((feature) => (
                              <div className="col-md-6">
                                 <p> <FiCheck />{feature}</p> 
                              </div>
                              ))}
                            </div>
                            <ul>
                            </ul>
                          </div>
                        </div>
                        <div className="total-price">
                          <span>TOTAL: ${total}.00 USD mensuales</span>
                        </div>
                      </div>
                    ) : null}
                    <div className="r-button-group">
                      {/* <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      > */}
                      <button
                        onClick={() => jumpTo(data.previousButton)}
                        className="btn-outlined btn-small round"
                        style={{ opacity: data.id == 1 ? "0" : 1 }}
                      >
                        Atras
                      </button>

                      {data.nextButton ? (
                        <button
                          onClick={data.id == 3 ? () => validateUser() : () => jumpTo(data.nextButton) }
                          className="btn-secondary btn-small round "
                        >
                          {data.id == 3 ? "Pagar" : "Siguiente"}
                        </button>
                      ) : null}
                      {/* </ScrollAnimation> */}
                    </div>
                        <br></br>
                    <div style={{"position":"relative", paddingTop:"20px"}}>
                            {data.id == 3 && show ? (
                                        <PayPalButtons
                                            style={{ layout: "horizontal", tagline :"false", }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}
                                        />
                                    ) : null  }
                          </div>
                  </div>
                </div>
                <div
                  className="order-1 order-lg-2 col-lg-6"
                  style={{ width: "50%" }}
                >
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={data.image}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* End Single Timeline  */}
    </div>

    <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title style= {{color: "Black" }}>{titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body style= {{color: "Black" }}>{bodyModal}</Modal.Body>
        <Modal.Footer>
          <Button className="btn-outlined btn-small round"  style={{color:"Black"}} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className="btn-secondary btn-small round" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

    </PayPalScriptProvider>
  );
};

export default TimelineOne;
