import React from "react";

const Nav = ({
  scrollToPorque,
  scrollToHub,
  scrollToUsos,
  scrollToBanner,
  scrollToPrice,
  handleContant,
  handlePolitics,
  handleRegister,
  handleLogin,
  handleTermins
}) => {
  return (
    <ul className="mainmenu">
      <li
        onClick={() => {
          setTimeout(() => {
            scrollToPorque()
          }, 30);
        }}
      >
        <p className="nav-item">¿Por qué Nextcloud?</p>
      </li>

      <li
        onClick={() => {
          setTimeout(() => {
            scrollToHub();
          }, 30);
        }}
      >
        <p className="nav-item">Nextcloud Hub</p>
      </li>

      <li
        className="has-droupdown"
        onClick={() => {
          setTimeout(() => {
            scrollToUsos();
          }, 30);
        }}
      >
        <p className="nav-item">Casos de uso</p>
      </li>

      <li
        onClick={() => {
          setTimeout(() => {
            scrollToPrice();
          }, 30);
        }}
      >
        <p className="nav-item">Precios</p>
      </li>
    </ul>
  );
};
export default Nav;
