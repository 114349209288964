import React, { useRef, useState } from "react";
import SEO from "../common/SEO";
import Header from "../common/header/Header";
import FooterFour from "../common/footer/FooterFour";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import EnterpriseLogo from "../assets/images/Banner-Enterpise.svg";
import eyeIcon from "../assets/images/Icon-eye.svg";
  //=====functions to scroll to sections====//
  
  const Recovery = () =>{
    const [register, setRegister] = useState(false);
    const [email, setEmail] = useState(null);
    const [showPwd, setShowPwd] = useState(false);
    const [password, setPassword] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const handleRegister = () => {
        setRegister(false);
      };
      const handleEmail = (event) => {
        setEmail(event.target.value);
      };
      const handlePassword = (event) => {
        setPassword(event.target.value);
      };
      const handleShowPwd = () => {
        setShowPwd(!showPwd);
      };
      
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const jumpTo = (section) => {
/*     switch (section) {
      case "step 1":
        scrollToStep1();
        break;
      case "step 2":
        scrollToStep2();
        break;
      case "step 3":
        scrollToStep3();
        break;
      default:
        console.log("Invalid section");
        break;
    } */
  };
    const porque = useRef(null);
    const hub = useRef(null);
    const usos = useRef(null);
    const enAccion = useRef(null);
    const price = useRef(null);

    const scrollToPorque = () => window.scrollTo(0, porque.current.offsetTop);
    const scrollToHub = () => window.scrollTo(0, hub.current.offsetTop);
    const scrollToUsos = () => window.scrollTo(0, usos.current.offsetTop);
    const scrollToEnAccion = () => window.scrollTo(0, enAccion.current.offsetTop);
    const scrollToPrice = () => window.scrollTo(0, price.current.offsetTop);
      //===== Style for input with search icon =====//
  const defaultInputStyle = {
    backgroundColor: "#fff",
    height: "40px",
    borderRadius: "10px",
    fontSize: "14px",
    marginBottom: "15px"
  };
  const inputStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "right 10px center",
    backgroundRepeat: "no-repeat", */
    paddingRight: "40px", // adjust this value to match the width of your icon
    boxSizing: "border-box",
    backgroundColor: "#fff",
    width: "80%",
    height: "40px",
    borderRadius: "10px",
    fontSize: "14px",
    paddingLeft: "10px",
    borderRadius: "10px 0px 0px 10px"
  };
  const inputButtonStyle1 = {
/*     backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",  */
    width: "15%",
    height: "40px",
    marginBottom: "3px",
    borderRadius: "0px 10px 10px 0px"
    
  }
    return(
      <>
      <SEO title="Nextcloud" />
      <main className="page-wrapper">
        <Header
          scrollToHub={scrollToHub}
          scrollToUsos={scrollToUsos}
          scrollToEnAccion={scrollToEnAccion}
          scrollToPorque={scrollToPorque}
          scrollToPrice={scrollToPrice}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          handleRegister={handleRegister}
        />

            {/* //===========Por que Nexcloud===========  */}
            <div className="rn-service-area rn-section-gap" ref={porque}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Restablecer contraseña"
                      title="Cambiemos tu contraseña"
                    />
                  </div>
                </div>
                <div className="single-content" style={{paddingBottom: "120px"}}>
            <div className="inner">

            <div className="card" style={{ border: "none", borderRadius: "var(--radius-big)",boxShadow: "8px 8px 20px rgba(0,0,0,.1)", background: "rgba(27,174,254,.1)"}}>
              <div className="card-body" style={{margin: "25px", padding: "25px"}}>
                <div className="container">
                  <div className="row">
                    <div className="col" style={{paddingTop:"65px"}}>
                      <h2 style={{color: "black"}}>Restablecer contraseña</h2>
                      <p>
                      Te enviaremos un enlace para restablecer tu contraseña
                      </p>
                    <form>
                      <input
                        style={defaultInputStyle}
                        type="email"
                        placeholder="Correo electrónico"
                        value={email}
                        onChange={handleEmail}
                      />
                      <button className="btn-secondary round" style={{width: "100%"}}>enviar </button>
                      <div>
                             <a href="/login" style={{color: "blue"}}>Iniciar sesión</a>
                      </div>
                    </form>
                    </div>

                    <div className="col">
                    <div className="thumbnail">
                    <img
                      className="w-100"
                      src={EnterpriseLogo}
                      alt="Corporate React Template"
                    />
                  </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
              </div>
              </div>
              </div>
            </div>
          
        <FooterFour />
      </main>
    </>
    )
}
export default Recovery