import React from "react";
import ScrollTop from "./ScrollTop";
import { BsFillTelephoneFill,BsGeoAltFill,BsEnvelopeFill } from "react-icons/bs";

const FooterFour = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default no-border">
        <div className="footer-top" style={{padding: "50px 0px 0px 0px"}}>
          <div className="container">
            <div className="row"> 
              <div className="col">
              <img
                    src="./images/logo/Nextcloud-logo-footer.svg"
                    alt="nextcloud logo"
                    style={{width:"20%"}}
                  />
              </div>
              <div className="col">
              <label style={{paddingTop:"35px", fontWeight:"bolder"}}>Contacto</label>
              </div>
            </div>
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-6">
                  <div className="inner">
                    <h6 className="">
                      Nextcloud ofrece una moderna plataforma de colaboración de
                      contenidos con edición de documentos en tiempo real,
                      videochat y trabajo en grupo en el móvil, el escritorio y
                      la web.
                    </h6>
                  </div>
                  <div>
                </div>
              </div>
              {/* End Single Widget  */}
              <div className="col-6">
                <div className="row">
                  <p style={{color: "white"}}>
                   <BsFillTelephoneFill />
                   &nbsp; +52 449 996 1460 
                  <br></br>
                   <BsEnvelopeFill/>
                   &nbsp;  ayuda@kiubix.com
                  <br></br>
                  <BsGeoAltFill/>
                  &nbsp; Av. Canal Interceptor 604, Las Arboledas, 20020 Aguascalientes,Ags
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center" style={{borderTop: "solid 2px rgba(27, 174, 254, 1)"}}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop: "20px"}}>
                <div className="copyright-left">
                  <ul className="ft-menu link-hover">
                    <li>
                      <a href="/politicas">Política de privacidad</a>
                    </li>
                    <li>
                      <a href="/condiciones">Términos y condiciones</a>
                    </li>
                    <li>
                      <a href="/contact">Contáctanos</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop: "20px"}}>
                <div className="copyright-right text-center text-md-end">
                  <p className="copyright-text">
                  Made With ❤️ By KIUBIX © 2021 - 2023 Nextcloud GmbH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterFour;
