import React, { useRef, useState } from "react";
import SEO from "../common/SEO";

import Header from "../common/header/Header";
import Pricing from "../elements/pricing/Pricing";

import ServiceOne from "../elements/service/ServiceOne";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CasosDeUso from "../elements/service/CasosDeUso";
import TabModule from "../elements/tab/TabModule";
import VideoItem from "../elements/video/VideoItem";
import Register from "../elements/Register/Register";
import Contant from "../elements/foteer/contact"
import Politics from "../elements/foteer/politics"
import Termins from "../elements/foteer/termins"
import Login from "../elements/login/login"
import { FaPhone,FaLocationDot,FaEnvelope, FaPlay, FaCheck} from "react-icons/fa6";
const Home = () => {
  const [total, setTotal] = useState(null);
  const [features, setFeatures] = useState([]);
  const [register, setRegister] = useState(false);
  const [contant, setContant] = useState(false)
  const [politics, setPolitics] = useState(false)
  const [termins, setTermins] = useState(false)
  const [login, setLogin] = useState(false)
  const [title, setTitle] = useState(null);
    //=====sections to scroll to home====//
    const porque = useRef(30);
    const hub = useRef(0);
    const usos = useRef(0);
    const price = useRef(0);
    const banner = useRef(0)

  const handleRegister = () => {
    setRegister(false);
  };
 const handleLogin = () =>{
    setLogin(false)
  } 
  const handleContant = () => {
    setContant(false);
    setRegister(false)
  };

  const handleContantLink = () => {
    setTermins(false);
    setPolitics(false);
    setContant(true)
    setLogin(false)
    setRegister(false)
    //setHander()
    window.scrollTo({
      top: 1,
      behavior: "instant",
    });
  };
  const handleLoginLink = () =>{
    setLogin(true)
    setTermins(false);
    setPolitics(false);
    setContant(false)
    setRegister(false)
    //setHander()
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  } 
  const handlePolitics = () => {
    setPolitics(false);
  };
  const handlePoliticsLink = () => {
    setTermins(false);
    setPolitics(true);
    setContant(false)
    setRegister(false)
    setLogin(false)
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const handleTermins = () => {
    setTermins(false);
  };
  const handleTerminsLink = () => {
    setTermins(true);
    setPolitics(false);
    setContant(false)
    setLogin(false)
    setRegister(false)
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const PopupData = [
    {
      id: "01",
      image: "./images/bg/bg-image-4.jpg",
      popupLink: [
        "https://www.youtube.com/watch?v=I8GtygCoNcY&list=PL4eBKdNy6FCHaRUTIRGpPSdAeiqfE--LS&index=12",
      ],
    },
  ];

  //====function that brings up register form====//
  const handlePriceLink = (price) => {
    setRegister(true);
    setLogin(false)
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    // set price to state variable
    switch (price) {
      case "price1":
        setTotal(4.00);
        setTitle("Nextcloud Starter")
        setFeatures([
          "Almacenamiento : 200 GB",
          "Proteccion : DDoS",
          "Soporte : 24/7",
          "99.99% Uptime",
          "Clientes : móviles y de escritorio",
          "Usuarios : Ideal 15",
        ]);
        break;
      case "price2":
        setTotal(7.00);
        setTitle("Nextcloud Standard")
        setFeatures([
          "Almacenamiento : 500 GB",
          "Proteccion : DDoS",
          "Soporte : 24/7",
          "99.99% Uptime",
          "Clientes : móviles y de escritorio",
          "Usuarios : Ideal 15",
        ]);
        break;
      case "price3":
        setTotal(15.00);
        setTitle("Nextcloud Premium")
        setFeatures([
          "Almacenamiento : 1 TB",
          "Proteccion : DDoS",
          "Soporte : 24/7",
          "99.99% Uptime",
          "Clientes : móviles y de escritorio",
          "Usuarios : Ideal 15",
        ]);
        break;
      case "price4":
        setTotal(35.00);
        setTitle("Nextcloud Advanced")
        setFeatures([
          "Almacenamiento : 3 TB",
          "Proteccion : DDoS",
          "Soporte : 24/7",
          "99.99% Uptime",
          "Clientes : móviles y de escritorio",
          "Usuarios : Ideal 15",
        ]);
        break;

      default:
        setTotal(0);
        setTitle("");
        setFeatures([""]);
        break;
    }
  };

  const scrollToPorque = () => {
    window.scrollTo(0, porque.current?.offsetTop);    
    setTermins(false);
    setRegister(false)
    setPolitics(false);
    setContant(false)
    setLogin(false)}
  const scrollToHub = () => {
    window.scrollTo(0, hub?.current?.offsetTop);     setTermins(false);
    setPolitics(false);
    setContant(false)
    setRegister(false)
    setLogin(false)}
  const scrollToUsos = () => {window.scrollTo(0, usos?.current?.offsetTop);    
    setTermins(false);
    setPolitics(false);
    setRegister(false)
    setContant(false)
    setLogin(false)}
  const scrollToPrice = () => {window.scrollTo(0, price?.current?.offsetTop);     setTermins(false);
    setPolitics(false);
    setRegister(false)
    setContant(false)
    setLogin(false)}
  const scrollToBanner = () => {window.scrollTo(0, banner?.current?.offsetTop);    setTermins(false);
    setPolitics(false);
    setRegister(false)
    setContant(false)
    setLogin(false)}
 
  //====function that brings up register form====//
  const goToPrices = () => {
    scrollToPrice();
  };

  return (
    <>
      <SEO title="Nextcloud" />
      <main className="page-wrapper">
      <div className="rn-gradient-circle"></div>
      <div className="rn-gradient-circle theme-pink"></div>
        <Header
          scrollToHub={scrollToHub}
          scrollToUsos={scrollToUsos}
          scrollToPorque={scrollToPorque}
          scrollToPrice={scrollToPrice}
          scrollToBanner={scrollToBanner}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          handleRegister={handleRegister}
          handleContant ={handleContant}
          handlePolitics = {handlePolitics}
          handleTermins = {handleTermins}
          handleLogin = {handleLoginLink}
        />
        {/* //======Create Account Section when register is set to true ======// */}
        { register ? (
          <Register total={total} title={title} features={features} />
        ):contant ? (
          <Contant />
        ):politics ? (
          <Politics/>
        ):termins ? (
          <Termins/>
        ):login ?(
          <Login/>
        ) : (
          <>
            {/* //========Banner========// */}
            <div className="banner height-750" ref= {banner}>
              <div className="container banner-container">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-xl-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                    <div className="inner text-start">
                      <span className="subtitle">NEXTCLOUD</span>
                      <h1 className="title">
                        Recupera el control de tus datos
                      </h1>
                      <ul className="list-icon">
                        <li>
                          <span className="icon">
                            <FaCheck />
                          </span>{" "}
                          Colaboración remota más fácil, en las instalaciones o
                          en la nube
                        </li>
                        <li>
                          <span className="icon">
                            <FaCheck />
                          </span>{" "}
                          Personalizable y escalable
                        </li>
                        <li>
                          <span className="icon">
                            <FaCheck />
                          </span>{" "}
                          No hay fugas de datos a terceros
                        </li>
                      </ul>
                      <div className="button-group mt--40">
                        <button
                          className="btn-default btn-medium round btn-icon"
                          onClick={goToPrices}
                        >
                          Prueba Nextcloud ahora
                        </button>
                        <a
                          className="btn-default btn-medium btn-border round btn-transparent"
                          /* onClick={handleContantLink}  */
                          style={{cursor: "pointer"}}
                        >
                          Contáctanos
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-xl-5 order-1 order-lg-2">
                    <div>
                      <img
                        className="w-100 h-100"
                        src="./images/banner/Nextcloud-Imagen-Banner.svg"
                        alt="Nextcloud Logo"
                      />
                    </div>
                  </div>
                </div>
                <hr className="hr-white" />
              </div>
            </div>
            <Separator />
            {/* //===========Por que Nexcloud===========  */}
            <div className="rn-service-area rn-section-gap" ref={porque}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Retoma el control"
                      title="¿Por qué Nextcloud?"
                      description="Con más de 400.000 implantaciones, Nextcloud es la plataforma de colaboración de contenidos más popular que se puede descargar. Se adapta desde una Raspberry Pi con 2 usuarios hasta instalaciones distribuidas globalmente con decenas de millones de usuarios en los principales proveedores de alojamiento. ¿Por qué es tan popular?"
                    />
                  </div>
                </div>

                <ServiceOne
                  serviceStyle="service__style--1 icon-circle-style"
                  textAlign="text-center"
                />
                <div className="mt--40 text-center">
                  <button
                    className="btn-secondary btn-medium round"
                    onClick={goToPrices}
                  >
                    Prueba Nextcloud ahora
                  </button>
                </div>
              </div>
            </div>
            {/* //=========== Nextcloud Hub ==============//   */}
            <div className="rn-service-area rn-section-gap" ref={hub}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Impulsando la colaboración"
                      title="Nextcloud Hub"
                      description="Hub integra los cuatro productos clave de Nextcloud: Files, Talk, Groupware y Office en una única plataforma, optimizando el flujo de colaboración."
                    />
                  </div>
                </div>
                <TabModule />
{/*                 <div className="mt--40 text-center">
                  <button
                    className="btn-secondary btn-medium round"
                    //onClick={goToRegister}
                  >
                    Reproducir video &nbsp;<FaPlay style={{fontSize: "25px"}}/>
                  </button>
                </div> */}
              </div>
            </div>
            <Separator />
            {/* //=============Casos de uso==============//  */}
            <div className="rn-service-area rn-section-gap " ref={usos}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Soluciones"
                      title="Casos de uso Nextcloud"
                      description="Nextcloud se compromete a proporcionar una tecnología que se adapta perfectamente a su organización"
                    />
                  </div>
                </div>
                <CasosDeUso
                  serviceStyle="service__style--1 bg-color-light-blue radius mt--25 rbt-border"
                  textAlign="text-start"
                />
                <div className="mt--40 text-center">
                  <button
                    className="btn-secondary btn-medium round"
                    onClick={goToPrices}
                  >
                    Prueba Nextcloud ahora
                  </button>
                </div>
              </div>
            </div>
            {/* //========Nuestros planes de precios========//  */}
            <div style={{ paddingBottom: "110px"}} className="rn-service-area rn-section-gap ">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12" ref={price}>
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Precios"
                      title="Nuestros planes de precios"
                      description="Nextcloud está diseñado para ofrecer la mejor productividad de su clase, y se desarrolla a un ritmo impresionante con nuevas funcionalidades disponibles cada pocos meses. Hemos seleccionado algunos vídeos para que te hagas una idea de lo que estamos haciendo."
                    />
                  </div>
                </div>
                <Pricing handlePriceLink={handlePriceLink} />
              </div>
            </div>
          </>
        )
        }

      <footer className="rn-footer footer-style-default no-border">
        <div className="footer-top" style={{padding: "50px 0px 0px 0px"}}>
          <div className="container">
            <div className="row"> 
              <div className="col">
              <img
                    src="./images/logo/Nextcloud-logo-footer.svg"
                    alt="nextcloud logo"
                    style={{ paddingBottom:"35px", width:"20%"}}
                  />
              </div>
              <br></br>
              <div className="col">
              <label style={{paddingTop:"35px", fontWeight:"bolder"}}>Contacto</label>
              </div>
            </div>
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-6">
                  <div className="inner">
                    <h6 className="">
                      Nextcloud ofrece una moderna plataforma de colaboración de
                      contenidos con edición de documentos en tiempo real,
                      videochat, trabajo en grupo en el móvil, el escritorio y
                      la web.
                    </h6>
                  </div>
                  <div>
                </div>
              </div>
              {/* End Single Widget  */}
              <div className="col-6">
                <div className="row">
                  <p style={{color: "white"}}>
                   <FaPhone />
                   &nbsp; +52 449 996 1460 
                  <br></br>
                   <FaEnvelope/>
                   &nbsp;  ayuda@kiubix.com
                  <br></br>
                  <FaLocationDot/>
                  &nbsp; Av. Canal Interceptor 604, Las Arboledas, 20020 Aguascalientes,Ags
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center" style={{borderTop: "solid 2px rgba(27, 174, 254, 1)"}}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop: "20px"}}>
                <div className="copyright-left">
                  <ul className="ft-menu link-hover">
                    <li>
                      <a onClick={handlePoliticsLink} style={{cursor: "pointer"}}>Política de privacidad</a>
                    </li>
                    <li>
                      <a onClick={handleTerminsLink} style={{cursor: "pointer"}}>Términos y condiciones</a>
                    </li>
                    <li>
                      <a onClick={handleContantLink} style={{cursor: "pointer"}}>Contáctanos</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop: "20px"}}>
                <div className="copyright-right text-center text-md-end">
                  <p className="copyright-text">
                  Made With ❤️ By <a href="https://international.kiubix.com/">KIUBIX</a> © 2023 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </main>
    </>
  );
};
export default Home;
